<template>
  <v-app id="inspire">
    <v-navigation-drawer
      app
      permanent
      mini-variant
      style="background-color: #1f1b3e !important;"
      v-if="isLoggedIn && !$vuetify.breakpoint.mobile"
    >
    <v-list-item class="px-2">
            <v-list-item-avatar>
              <v-img src="/logo.png" />
            </v-list-item-avatar>
          </v-list-item>

          <v-divider></v-divider>

          <v-list
            dense
            nav
          >
            <v-list-item
              v-for="item in items"
              :key="item.title"
              :to="item.link"
              link
            >
              <v-list-item-icon>
                <v-icon color="white">{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <!-- logout bottom -->
          <v-list-item
            :style="{ position: 'absolute', bottom: '0' }"
            link
            @click="logout"
          >
            <v-list-item-icon>
              <v-icon color="white">mdi-logout</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
    </v-navigation-drawer>


    <v-main class="mb-12">
      <router-view />
    </v-main>


    <v-col style="position:fixed;bottom:0;background: linear-gradient(0deg, #00000063, transparent);" class="text-center" v-if="isLoggedIn && $vuetify.breakpoint.mobile" cols="12">
        <v-btn fab dark color="primary" @click="$router.push('/')">
          <v-img height="30" contain src="/logo.png" />
        </v-btn>
    </v-col>
    <!-- logout -->
    <v-btn
      v-if="isLoggedIn && $vuetify.breakpoint.mobile"
      fab
      dark
      x-small
      outlined
      color="grey"
      :style="{ position: 'fixed', bottom: '15px', right: '15px' }"
      @click="logout" 
    >
      <v-icon>mdi-logout</v-icon>
    </v-btn>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { Capacitor } from '@capacitor/core';
import axios from "axios";

export default {
  name: "App",
  computed: {
    ...mapState(["isLoggedIn", "isPro", "id_user"]),
  },
  data: () => ({
    Capacitor:Capacitor,
    drawer: null,
    items: [
      { title: "Home", icon: "mdi-chart-multiline", link: "/" },
      { title: "Settings", icon: "mdi-cog", link: "/profile" },
      // { title: "About", icon: "mdi-information", link: "/about" },
    ],
  }),
  methods: {
    unlockApp(event) {
      console.log("event", event);
      if (event.state == "approved") {
        this.$store.dispatch("unlockApp", true);
      }
    },
    logout() {
      localStorage.clear();
      axios.defaults.headers.common["Authorization"] = "";
      this.$store.dispatch("setLoggedIn", false);
      this.$router.push("/login");
    },
    checkStoreState() {
      this.$iapstore.register([
        {
          id: "proyearly",
          type: this.$iapstore.PAID_SUBSCRIPTION,
        },
        {
          id: "promonthly",
          type: this.$iapstore.PAID_SUBSCRIPTION,
        },
      ]);

      this.$iapstore.when("proyearly").updated(this.unlockApp); // match a specific product
      this.$iapstore.when("promonthly").updated(this.unlockApp); // match a specific product
      this.$iapstore.when("proyearly").owned(this.unlockApp); // match a specific product
      this.$iapstore.when("promonthly").owned(this.unlockApp); // match a specific product

      // Show errors for 10 seconds.
      this.$iapstore.error(function (error) {
        console.log(error);
      });

      // Called when any subscription product is updated
      this.$iapstore.when("subscription").updated(function () {
        const product1 = this.$iapstore.get("proyearly") || {};
        const product2 = this.$iapstore.get("promonthly") || {};
        console.log("yearly : ", product1);
        console.log("monthly : ", product2);
      });

      // Later, we will add our events handlers here

      // Load informations about products and purchases
      this.$iapstore.refresh();
    },
    getCryptos() {
      this.$axios.get("/api/stocks").then((res) => {
        this.$store.dispatch("setCryptos", res.data.data);
      });
    },
  },
  mounted() {
    this.getCryptos();
    if (process.env.NODE_ENV == "development") {
      this.$store.dispatch("unlockApp", true);
    }
    if(this.id_user && this.Capacitor.getPlatform() == "web")
    {
      this.$axios.get('/api/users/me').then((res)=>{
        let expiration_at = res.data.expiration_date
        let now = new Date()
        if(expiration_at && now < new Date(expiration_at)){
          this.$store.dispatch("unlockApp", true);
        }else{
          this.$store.dispatch("unlockApp", false);
        }
      }).catch(()=>{
        this.$store.dispatch("unlockApp", false)
      });
    }
    if(!this.Capacitor.getPlatform() == "web")
    {
      this.checkStoreState();
    }
  },
};
</script>

<style lang="scss">

.theme--dark.v-application, html {
  background-color: #131128 !important;
}
.theme--dark.v-card{
  background-color:#1f1b3e !important;
}

header.v-sheet.theme--dark.v-toolbar.v-app-bar.v-app-bar--fixed.primary {
  border-radius: 30px;
  transform: translateY(30px) !important;
  margin: 10px;
  top: inherit;
  bottom: 3em;
}


@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600;700;800;900&display=swap");

$body-font-family: "Maven Pro";
$title-font: "Maven Pro";
.v-application {
  font-family: $body-font-family, sans-serif !important;
  .title {
    // To pin point specific classes of some components
    font-family: $title-font, sans-serif !important;
  }
}
</style>
