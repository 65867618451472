import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
Vue.use(VueRouter)
import axios from 'axios'
const isAuth = async (from, to, next) => {
  // next()
  /* This is a guard that checks if the user is logged in. If they are, they can proceed to the next
  page. If they are not, they are redirected to the login page. */
  next();
  if (store.state.id_user && store.state.isLoggedIn) {
    let token = localStorage.getItem('jwt')
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
    axios.get('/api/users/me').then((response) => {
      if (response.data) {
        store.dispatch('setUser', response.data)
        store.dispatch('setLoggedIn', true)
        store.dispatch('setIdUser', response.data.id)
        let expiration_date = new Date(response.data.expiration_date)
        store.dispatch('unlockApp', expiration_date > new Date())
      }
    })
    next()
  } else {
    next('/login')
  }
}

const routes = [
  {
    path: '/',
    name: 'splash',
    component: () => import('@/views/DashboardView'),
    beforeEnter: isAuth
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/RegisterView')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/AboutView')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('@/views/NewsView')
  },
  {
    path: '/pay',
    name: 'pay',
    component: () => import('@/views/PaymentView')
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/ProfileView'),
    beforeEnter: isAuth
  },
  {
    path: '/pin',
    name: 'pin',
    component: () => import('@/views/PinView')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/DashboardView'),
    beforeEnter: isAuth
  },
  {
    path: '/crypto/:currency',
    name: 'crypto',
    component: () => import('@/views/CryptoView'),
    beforeEnter: isAuth
  },
  {
    path: '/payment/:type',
    name: 'payment',
    component: () => import('@/views/PaymentType'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
