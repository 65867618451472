import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    id_user: localStorage.getItem('id_user'),
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
    isLoggedIn: localStorage.getItem('isLoggedIn'),
    isPro: false,
    favorites:[],
    cryptos: [],
    jwt: localStorage.getItem('jwt') ? localStorage.getItem('jwt') : null
  },
  getters: {},
  mutations: {
    SET_CRYPTOS(state, value) {
      state.cryptos = value;
    },
    SET_LOGGED_IN(state, value) {
      state.isLoggedIn = value
      localStorage.setItem('isLoggedIn', value)
    },
    SET_UNLOCK_APP(state, value) {
      state.isPro = value
      localStorage.setItem('isPro', value)
    },
    SET_ID_USER(state, value) {
      state.id_user = value;
      localStorage.setItem('id_user', value)
    },
    SET_FAVORITES(state, value) {
      state.favorites = value;
    },
    SET_USER(state, value){
      state.user = value;
      localStorage.setItem('user', JSON.stringify(value))
    },
    SET_JWT(state, value){
      state.jwt = value;
      localStorage.setItem('jwt', value)
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + value
    }
  },
  actions: {
    setLoggedIn({ commit }, value) {
      commit('SET_LOGGED_IN', value)
    },
    unlockApp({ commit }, value) {
      commit('SET_UNLOCK_APP', value)
    },
    setIdUser({commit }, value) {
      commit('SET_ID_USER', value);
    },
    setFavorites({commit }, value) {
      commit('SET_FAVORITES', value);
    },
    setCryptos({ commit }, value) {
      commit('SET_CRYPTOS', value);
    },
    setUser({ commit }, value) {
      commit('SET_USER', value)
    },
    setJwt({ commit }, value) {
      commit('SET_JWT', value)
    }
  },
  modules: {},
})
