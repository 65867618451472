import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import VueApexCharts from 'vue-apexcharts'
import axios from 'axios';
import { Capacitor } from '@capacitor/core';

import { InAppPurchase2 } from "@ionic-native/in-app-purchase-2";
import moment from 'moment-timezone'

console.log(process.env.VUE_APP_API_URI);
axios.defaults.baseURL = process.env.VUE_APP_API_URI || 'https://crydictback.simercastudio.com';


// check when axios return 4xx error and redirect to login
axios.interceptors.response.use(response => {
  // Si la réponse est réussie, la renvoyer telle quelle
  return response;
},
  error => {
    // Si une erreur 4xx est détectée, rediriger vers la page /login
    if (error.response && error.response.status >= 400 && error.response.status < 500) {
      localStorage.clear();
      if(window.location.href.indexOf('login') === -1 && window.location.href.indexOf('register') === -1){
        window.location.href = '/login';
      }
    }

    // Si une autre erreur se produit, la renvoyer telle quelle
    return Promise.reject(error);
  });


Vue.prototype.$axios = axios;
Vue.prototype.$iapstore = InAppPurchase2;
Vue.prototype.$capacitor = Capacitor;
Vue.prototype.$moment = moment;

Vue.use(VueApexCharts)

Vue.component('ApexChart', VueApexCharts)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
